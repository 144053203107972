import ConsultaDiploma from "../pages/ConsultaDiploma/ConsultaDiploma";
import Error404 from "../pages/Error404";


export default [
  {
    path: '/consulta/:codigo',
    exact: true,
    page: ConsultaDiploma
  },
  {
    path: '*',
    exact: true,
    page: Error404
  }
]