import React from 'react';
import './resumenDiploma.css'
const ResultadoDiploma = ({diploma}) => {
    return (
       <div>
           <div className="titulo_certificado" dangerouslySetInnerHTML={{__html:diploma.titulo_certificado}}>
           </div>
           <table className="table">
               <thead>
                   <tr>
                       <th style={{textAlign:'center'}} colSpan={2}>Datos del diploma</th>
                   </tr>
               </thead>
               <tbody>
                    <tr>
                       <td>Otorgado a:</td>
                       <td>{diploma.nombre_usuario} {diploma.apellido_usuario}</td>
                   </tr>
                   <tr>
                       <td>Producto:</td>
                       <td>{diploma.nombre_proyecto}</td>
                   </tr>

                   <tr>
                       <td>Horas Academicas:</td>
                       <td>{diploma.horas_academicas}</td>
                   </tr>
                   <tr>
                       <td>Horas Lectivas:</td>
                       <td>{diploma.horas_lectivas}</td>
                   </tr>
                   <tr>
                       <td>Fecha Emisión:</td>
                       <td>{diploma.fecha_emision}</td>
                   </tr>
                   <tr>
                       <td>N° de Evento</td>
                       <td>{diploma.n_evento}</td>
                   </tr>
                   <tr>
                       <td>N° de Libro</td>
                       <td>{diploma.n_libro}</td>
                   </tr>
                   <tr>
                       <td>Nota</td>
                       <td>{diploma.nota}</td>
                   </tr>
               </tbody>
           </table>
       </div>
    );
};

export default ResultadoDiploma;