import React, { useEffect, useState } from "react";
import BasicLayout from "../../layout/BasicLayout/index";
import Loading from "../../components/Loading";
import HeadEncuesta from "../../components/HeadEncuesta";
import { useHistory, useParams } from "react-router-dom";
import {
  obtenerDiploma
} from "../../_actions/formularioActions";
import "./ContratoDigital.scss";
import { useDispatch, useSelector } from "react-redux";
import ResultadoDiploma from "./ResultadoDiploma";

const ConsultaDiploma = (props) => {
  const params = useParams();
  const { codigo } = params;
  const [activo, setActivo] = useState(false);
  const [diploma,setDiploma] = useState({})
  const[redirigir,setRedirigir] = useState(false)
  const {loading} = useSelector(state=>state.formulario);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await dispatch(obtenerDiploma(codigo));
      setActivo(response.estado);
      setRedirigir(!response.estado);
      setDiploma(response.diploma[0])
      // ...
     // console.log(response.departamentos)
    }
    fetchData();
  }, []);
  
  useEffect(()=>{
    if(redirigir && !loading)
    {
       history.push(`/Error404`)
    }
   },[redirigir,loading])

  if (loading) return <Loading />;

  return (
    <BasicLayout
      header={false}
      title={"Consulta de Diploma"}
    >
      <div className="formulario-encuesta">
        <div className="main-encuesta shadow">
          <HeadEncuesta
            title={"Diploma"}
            descripcion={"Este es un resumen de la información de su diploma:"}
          />
          <div className="body-encuesta bg-white p-md-5 pt-md-1 p-2">
           { activo ? <ResultadoDiploma diploma={diploma} />: 'Inactivo'}
          </div>
          <div className="foot">
            © {new Date().getFullYear()} INEDI Posgrados &reg; Privacy & Cookies
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default ConsultaDiploma;
